import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import PrivateRoutes from "../ProtectedRoutes"
import Login from "./pages/Login/Login"
import React from "react"
import PrincipalLayout from "./ui/PrincipalLayout"
import ResetPasswordRequest from "./pages/ResetPasswordRequest/ResetPasswordRequest"
import ResetPasswordConfirmation from "./pages/ResetPasswordConfirmation/ResetPasswordConfirmation"
import CreateAccount from "./pages/CreateAccount/CreateAccount"
import AccountExistsOrExpiredLinkError from "./pages/CreateAccount/AccountExistsOrExpiredLinkError"

const App: React.FC = () => {
	return (
		<div className="App">
			<Router>
				<PrincipalLayout>
					<Routes>
						<Route element={<PrivateRoutes />}>
							<Route element={<Login />} path="/login" />
							<Route element={<ResetPasswordRequest />} path="/reset-password-request" />
							<Route element={<ResetPasswordConfirmation />} path="/reset-password-confirmation" />
							<Route element={<CreateAccount />} path="/create-account" />
							<Route element={<AccountExistsOrExpiredLinkError />} path="/create-account-validation-error" />
							<Route path="*" element={<Navigate to="/login" replace />} />
						</Route>
						<Route path="/logout-callback" element={<Navigate to="/login" />}>
						</Route>
					</Routes>
				</PrincipalLayout>
			</Router>
		</div >
	)
}

export default App
