import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { CONSTANTS } from "../../utils/constants"
import Alert from "../../components/Alert/Alert"
import DileCaptcha from "../../components/DileCaptcha/DileCaptcha"
import SubmitButton from "../../components/SubmitButton/SubmitButton"
import Input from "../../components/Input/Input"
import PasswordPolicy from "../../components/PasswordPolicy/PasswordPolicy"
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert"
import { AxiosError } from "axios"
import { BaseResponse, confirmResetPassword } from "../../services/api"
import { addErrorIf, redirectToApp } from "../../utils/utils"
import Description from "../../components/Description/Description"

interface ResetPasswordConfirmationFormProps {
    resetCode: string
}

const ResetPasswordConfirmationForm: React.FC<ResetPasswordConfirmationFormProps> = ({ resetCode }) => {
    const [captchaUuid, setCaptchaUuid] = useState<any>()
    const [captchaCode, setCaptchaCode] = useState<any>()
    const captchetatRef = useRef<any>(null)
    const [invalidCaptcha, setInvalidCaptcha] = useState<string>()
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [title, setTitle] = useState<string>(CONSTANTS.DEFAULT_RESET_PASSWORD_CONFIRMATION_PAGE_TITLE)
    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>("")
    const [invalidPasswordFormat, setInvalidPasswordFormat] = useState<string>()
    const [notSamePassword, setNotSamePassword] = useState<string>()

    function handleError(error: AxiosError<BaseResponse<void>>) {
        const INVALID_PASSWORD_FORMAT = "200"
        const INVALID_CAPTCHA = "600"
        const PASSWORD_AND_CONFIRMATION_NOT_SAME = "700"
        const ERRORS = error.response?.data?.errors || {}
        const FORM_ERRORS: string[] = []


        addErrorIf(INVALID_CAPTCHA in ERRORS, FORM_ERRORS, INVALID_CAPTCHA, setInvalidCaptcha)
        addErrorIf(INVALID_PASSWORD_FORMAT in ERRORS, FORM_ERRORS, INVALID_PASSWORD_FORMAT, setInvalidPasswordFormat)
        addErrorIf(PASSWORD_AND_CONFIRMATION_NOT_SAME in ERRORS, FORM_ERRORS, PASSWORD_AND_CONFIRMATION_NOT_SAME, setNotSamePassword)
        setErrorMessages(FORM_ERRORS)
        setTitle("Erreur - " + CONSTANTS.DEFAULT_RESET_PASSWORD_CONFIRMATION_PAGE_TITLE)
        captchetatRef.current?.reloadImage()
        setCaptchaCode("")
        window.scrollTo({ top: 0 })
    }

    const submitResetPasswordConfirmation = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setInvalidCaptcha("")
        setInvalidPasswordFormat("")
        setNotSamePassword("")
        setErrorMessages([])

        const requestBody = {
            newPassword: newPassword,
            newPasswordConfirmation: newPasswordConfirmation,
            resetCode: resetCode,
            captcha: {
                code: captchaCode,
                uuid: captchaUuid
            }
        }

        confirmResetPassword(requestBody)
            .then(redirectToApp)
            .catch(handleError)
    }

    return (

        <>
            <ErrorAlert errors={errorMessages} />
            <Helmet><title>{title}</title></Helmet>
            <Description hiddenMessage title="Mise à jour de votre mot de passe" />
            <form onSubmit={submitResetPasswordConfirmation}>
                <Alert className="fr-mb-6w">
                    <p>Les <strong>informations </strong>demandées sont <strong>obligatoires</strong></p>
                </Alert>
                <PasswordPolicy title="Choisir votre nouveau mot de passe" />
                <Input id="new-password" type="password" name="new-password" required label="Nouveau mot de passe"
                    value={newPassword} onChange={setNewPassword} error={invalidPasswordFormat} />
                <Input id="new-password-confirmation" type="password" name="new-password-confirmation" required
                    label="Confirmez votre mot de passe" value={newPasswordConfirmation}
                    onChange={setNewPasswordConfirmation}
                    error={notSamePassword} />
                <DileCaptcha captchaCode={captchaCode} setCaptchaCode={setCaptchaCode} setCaptchaUuid={setCaptchaUuid}
                    captchetatRef={captchetatRef} error={invalidCaptcha} />
                <SubmitButton title="Continuer" />
            </form>
        </>
    )
}

export default ResetPasswordConfirmationForm
