import React, { useEffect, useState } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { authMe } from './App/services/api'
import { redirectToApp } from './App/utils/utils'

const ProtectedRoutes: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isAuthorized, setIsAuthorized] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authMe()
                setIsAuthorized(response.ok)
            } catch (error) {
                return <Navigate to="/login" />
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [])

    if (isLoading) {
        return null
    }

    if (isAuthorized) {
        redirectToApp()
        return null
    }

    return <Outlet />
}

export default ProtectedRoutes