import React from "react"
import { CONSTANTS } from "../../utils/constants"
import { Helmet } from "react-helmet"
import Alert from "../../components/Alert/Alert"
import { resendCreateAccountValidationEmail } from "../../services/api"
import { useNavigate } from "react-router-dom"
import Description from "../../components/Description/Description"

interface CreateAccountConfirmationProps {
    code: string,
    email: string
}

const CreateAccountConfirmation: React.FC<CreateAccountConfirmationProps> = (props) => {
    const navigate = useNavigate()
    const sendAnotherMail = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        resendCreateAccountValidationEmail(props.code)
            .then(response => navigate("/create-account", { state: { resendEmailCode: response.data, email: props.email } }))
            .catch(() => navigate("/create-account-validation-error"))
    }
    return (
        <>
            <Helmet><title>{CONSTANTS.DEFAULT_CREATE_ACCOUNT_CONFIRMATION_PAGE_TITLE}</title></Helmet>
            <Description title="Activer mes identifiants" hiddenMessage/>
            <Alert type="success" role="status" className="fr-mb-6w">
                <h2 className="fr-text fr-text--lg">
                    C’est presque terminé !
                </h2>
                <p>
                    <strong>Veuillez vérifier votre messagerie</strong> <br />
                    et terminer la création de votre compte en cliquant sur <strong>le lien de confirmation
                    </strong> envoyé à votre adresse email {props.email}
                </p>
                <p>
                    Vous n’avez pas reçu d’email ? <a href="/" onClick={sendAnotherMail}>Recevoir un autre email</a>
                </p>
            </Alert>
        </>
    )
}

export default CreateAccountConfirmation