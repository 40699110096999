import Config from "../../config"
import { ERROR_CONSTANTS } from "./constants"

export const addErrorIf = (predicate: boolean, errors: string[], errorCode: string, setErrorMessage?: (param: string) => void) => {
	if (predicate) {
		errors.push(ERROR_CONSTANTS[errorCode])
		setErrorMessage?.(ERROR_CONSTANTS[errorCode])
	}
}

export const redirectToApp = () => {
	window.location.href = Config.APP_URL
}