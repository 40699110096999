import React from "react"
import "./AuthLink.css"
import { Link } from "react-router-dom"

interface AuthLinkProps {
	message: string,
	to: string
}

const AuthLink: React.FC<AuthLinkProps> = (props) => {
	return (
		<p className="fr-mt-1w dile-text--right">
			<Link className="fr-link fr-text--sm fr-fi-arrow-right-line fr-link--icon-right"
				to={props.to}>
				{props.message}
			</Link>
		</p>
	)
}

export default AuthLink