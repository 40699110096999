import React, { ReactNode } from "react"
import "./Callout.css"

interface AlertProps {
	className?: string,
	color?: string,
	icon?: string
	children: ReactNode
}

const Callout: React.FC<AlertProps> = (props) => {
	return (
		<>
			<div className={classes(props)} >
				{props.icon &&
					<span className={props.icon} aria-hidden="true"></span>
				}
				<div>
					{props.children}
				</div>
			</div>
		</>
	)
}

function classes(props: AlertProps): string {
	const classes = Array.of("fr-callout")

	if (props.className) {
		classes.push(props.className)
	}

	if (props.color) {
		classes.push(`fr-callout--${props.color}`)
	}


	return classes.join(" ")
}

export default Callout