import { CaptchEtat } from 'captchetat-react';
import Config from "../../../config"
import Input from "../Input/Input"


const DileCaptcha = ({ captchaCode, setCaptchaCode, setCaptchaUuid, captchetatRef, error }) => {

	if (!Config.DILE_CAPTCHA_ENABLED) {
		return <></>
	}
	return (
		<>
			<h3 className="fr-h6 fr-mt-6w">Code de sécurité</h3>
			<div className="fr-my-2w">
				<CaptchEtat urlBackend={Config.URL_SIMPLE_CAPTCHA_ENDPOINT} captchaStyleName="alphanumerique4to6LightCaptchaFR"
					onChange={(event) => setCaptchaUuid(event.uuid)} className="captchetat" ref={captchetatRef} />
			</div>
			<p className="fr-text--sm fr-mb-1w" id="captchaFormulaireExtInput-hint">
				Pour <strong>afficher un nouveau code</strong> ou <strong>écouter le code</strong>, utilisez les boutons situés à côté de l'image
			</p>
			<Input id="captchaFormulaireExtInput" type="text" name="code" required
				onChange={(event) => setCaptchaCode(event)}
				value={captchaCode}
				label="Recopiez le code de sécurité"
				hint="Le code de sécurité est composé de chiffres et de lettres" error={error} />
		</>
	)

}

export default DileCaptcha