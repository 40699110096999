import React, { ReactNode } from "react"

interface AlertProps {
	className?: string,
	type?: string,
	role?: string,
	hidden?: boolean,
	children: ReactNode
}

const Alert: React.FC<AlertProps> = (props) => {
	return (
		<div className={classes(props)} {...(props.role && { role: props.role })}>
			{props.children}
		</div>
	)
}

function classes(props: AlertProps): string {
	const classes = Array.of("fr-alert")

	if (props.className) {
		classes.push(props.className)
	}

	if (props.hidden) {
		classes.push("dile-display--none")
	}

	switch (props.type) {
		case "success":
			classes.push("fr-alert--success")
			break

		case "warning":
		case "error":
			classes.push("fr-alert--error")
			break

		default:
			classes.push("fr-alert--info")
			break
	}


	return classes.join(" ")
}

export default Alert