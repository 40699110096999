import React from "react"
import Alert from "../Alert/Alert"

interface ErrorAlertProps {
    errors: string[],
}

const ErrorAlert: React.FC<ErrorAlertProps> = (props) => {
    return (
        <Alert hidden={!props.errors.length} type="error" role="alert" className="fr-mb-6w">
            <ul>
                {
                    props.errors.map(error => <li>{error}</li>)
                }
            </ul>
        </Alert>
    )
}

export default ErrorAlert