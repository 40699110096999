const COMMON_TITLE = "Votre demande d'inscription sur les listes électorales | service-public.fr"

export const CONSTANTS = {
	DEFAULT_LOGIN_PAGE_TITLE: `Me connecter - ${COMMON_TITLE}`,
	DEFAULT_RESET_PASSWORD_REQUEST_PAGE_TITLE: `Mot de passe oublié ? - ${COMMON_TITLE}`,
	DEFAULT_RESET_PASSWORD_CONFIRMATION_PAGE_TITLE: `Mise à jour de votre mot de passe - ${COMMON_TITLE}`,
	DEFAULT_CREATE_ACCOUNT_PAGE_TITLE: `Choisir mes identifiants - ${COMMON_TITLE}`,
	DEFAULT_CREATE_ACCOUNT_CONFIRMATION_PAGE_TITLE: `Vérification de l'adresse email - ${COMMON_TITLE}`
}

export const ERROR_CONSTANTS: { [key: string]: string } = {
	200: "Votre mot de passe doit contenir [au moins 8 caractères], [au moins 1 lettre en majuscule], [au moins 1 lettre en minuscule], [au moins 1 chiffre]",
	300: "Un compte existe déjà pour cette adresse email",
	500: "Votre adresse email ou votre mot de passe est invalide. Merci de vérifier et de corriger votre saisie. Si vous êtes utilisateur ou utilisatrice de France Connect, cliquez sur le bouton France Connect pour vous identifier. Nous vous rappelons que ce service en ligne n’est pas lié à votre compte service-public.fr.",
	600: "Le code saisi ne correspond pas au code affiché. Veuillez recopier le nouveau code proposé.",
	700: "Le mot de passe et sa confirmation ne sont pas identiques. Merci de corriger votre saisie"
}