import React, { useEffect, useState } from "react"
import { checkResetPasswordCode } from "../../services/api"
import { useLocation } from "react-router-dom"
import ResetPasswordConfirmationForm from "./ResetPasswordConfirmationForm"
import ResetPasswordConfirmationCodeNotFound from "./ResetPasswordConfirmationCodeNotFound"

const ResetPasswordConfirmation: React.FC = () => {
    const [resetCodeNotFound, setResetCodeNotFound] = useState<boolean>()
    const [message, setMessage] = useState<string>("")
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const resetCode = searchParams.get('resetCode')

    useEffect(() => {
        if (resetCode === null) {
            setResetCodeNotFound(true)
            setMessage("Une erreur est survenue, veuillez vous reconnecter à votre application.")
            return
        }

        checkResetPasswordCode(resetCode)
            .then(() => setResetCodeNotFound(false))
            .catch(() => {
                setResetCodeNotFound(true)
                setMessage("Action expirée. Merci de continuer la connexion.")
            })
    }, [resetCode])

    if (resetCodeNotFound === undefined) {
        return <></>
    }

    return (
        <>
            {resetCodeNotFound
                ?
                <ResetPasswordConfirmationCodeNotFound message={message} />
                :
                <ResetPasswordConfirmationForm resetCode={resetCode!} />
            }
        </>
    )
}

export default ResetPasswordConfirmation