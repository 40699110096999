import axios from "axios"
import Config from "../../config"

const api = axios.create({ withCredentials: true })

interface Captcha {
    uuid: string,
    code: string
}

interface LoginBody {
    username: string,
    password: string,
    captcha: Captcha
}

interface RequestResetPasswordBody {
    email: string,
    captcha: Captcha
}

interface ConfirmResetPasswordBody {
    newPassword: string,
    newPasswordConfirmation: string,
    captcha: Captcha
}

interface CreateAccountBody {
    email: string,
    password: string,
    passwordConfirmation: string,
    captcha: Captcha
}

export interface BaseResponse<T> {
    ok: boolean,
    data?: T,
    errors?: { [key: string]: string }
}

export const authMe = (): Promise<BaseResponse<void>> => {
    return api.get(Config.URL_ME).then(response => response.data)
}

export const login = (body: LoginBody): Promise<BaseResponse<void>> => {
    return api.post(Config.URL_LOGIN, body)
        .then(response => response.data)
}

export const requestResetPassword = (body: RequestResetPasswordBody): Promise<BaseResponse<void>> => {
    return api.post(Config.URL_RESET_PASSWORD_REQUEST, body)
        .then(response => response.data)
}

export const confirmResetPassword = (body: ConfirmResetPasswordBody): Promise<BaseResponse<void>> => {
    return api.post(Config.URL_RESET_PASSWORD_CONFIRMATION, body)
        .then(response => response.data)
}

export const checkResetPasswordCode = (resetCode: string): Promise<void> => {
    return api.get(Config.URL_CHECK_RESET_PASSWORD_CODE, { params: { code: resetCode } })
        .then(response => response.data)
}

export const createAccount = (body: CreateAccountBody): Promise<BaseResponse<string>> => {
    return api.post(Config.URL_CREATE_ACCOUNT, body).then(response => response.data)
}

export const resendCreateAccountValidationEmail = (code: string): Promise<BaseResponse<string>> => {
    return api.get(`${Config.URL_RESEND_CREATE_ACCOUNT_VALIDATION_EMAIL}/${code}`)
        .then(response => response.data)
}
