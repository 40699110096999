import React from "react"
import Alert from "../../components/Alert/Alert"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import { CONSTANTS } from "../../utils/constants"

interface LinkItem {
    title: string,
    to: string
}

interface AccountExistsOrExpiredLinkErrorProps {
    title?: string,
    links?: LinkItem[],
    type?: string
}

const AccountExistsOrExpiredLinkError: React.FC<AccountExistsOrExpiredLinkErrorProps> = (
    {
        title = "Ce lien n'est plus actif. Veuillez vous authentifier ou créer un compte.",
        links = [{ title: "Se connecter", to: "/login" }, { title: "Créer un compte", to: "/create-account" }],
        ...props
    }
) => {

    return (
        <>
            <Helmet><title>{"Erreur - " + CONSTANTS.DEFAULT_CREATE_ACCOUNT_PAGE_TITLE}</title></Helmet>
            <Alert type={props.type} className="fr-mb-6w">
                <h2 className="fr-alert__title">{title}</h2>
                <ul className="fr-raw-list">
                    {
                        links.map(link =>
                            <li>
                                <Link to={link.to} className="fr-link fr-fi-arrow-right-line fr-link--icon-left">
                                    <span>{link.title}</span>
                                </Link>
                            </li>

                        )
                    }
                </ul>
            </Alert>
        </>
    )
}

export default AccountExistsOrExpiredLinkError