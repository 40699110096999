import React, { ReactNode, useRef } from "react"
import Header from "./Header"
import Footer from "./Footer"
import "./PrincipalLayout.css"

type PrincipalLayoutProps = {
	children: ReactNode
}

const PrincipalLayout: React.FC<PrincipalLayoutProps> = ({ children }: PrincipalLayoutProps) => {
	const topRef = useRef<HTMLDivElement>(null)
	const goToTop = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		topRef.current?.scrollIntoView({ behavior: 'smooth' })
	}

	return (
		<div ref={topRef}>
			<Header />

			<div className="fr-container">
				<main
					className="main"
					id="main"
					role="main"
				>
					<div className="fr-grid-row">
						<div className="fr-col-12 fr-col-offset-md-1 fr-col-md-10 fr-col-offset-lg-3 fr-col-lg-6">
							<h1 className="dile-titre-demarche fr-my-6w dile-text--center">
								Votre demande d’inscription sur les listes électorales
							</h1>
							{children}
						</div>
					</div>
					<p className="dile-text--right fr-mt-6w">
						<span className="fr-fi-arrow-up-line" aria-hidden="true"></span>
						<a href="/" onClick={goToTop}><span>Retour haut de page</span></a>
					</p>
				</main>
			</div>

			<Footer />

		</div>
	)
}

export default PrincipalLayout
