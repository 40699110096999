import React, { useEffect, useRef, useState } from "react"
import DileCaptcha from "../../components/DileCaptcha/DileCaptcha"
import "./Login.css"
import { AxiosError } from "axios"
import Input from "../../components/Input/Input"
import AuthLink from "../../components/AuthLink/AuthLink"
import FranceConnect from "../../components/FranceConnect/FranceConnect"
import Alert from "../../components/Alert/Alert"
import Callout from "../../components/Callout/Callout"
import { CONSTANTS } from "../../utils/constants"
import { BaseResponse, login } from "../../services/api"
import SubmitButton from "../../components/SubmitButton/SubmitButton"
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert"
import { Helmet } from "react-helmet"
import BlueRoundedOr from "../../components/BlueRoundedOr/BlueRoundedOr"
import { addErrorIf, redirectToApp } from "../../utils/utils"
import Description from "../../components/Description/Description"


const Login: React.FC = () => {
    const [captchaUuid, setCaptchaUuid] = useState<any>()
    const [captchaCode, setCaptchaCode] = useState<any>()
    const captchetatRef = useRef<any>(null)
    const [invalidCaptcha, setInvalidCaptcha] = useState<string>()
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [hideResetPasswordAlert, setHideResetPasswordAlert] = useState<boolean>(true)
    const [title, setTitle] = useState<string>(CONSTANTS.DEFAULT_LOGIN_PAGE_TITLE)

    useEffect(() => {
        const resetPasswordEmail = localStorage.getItem("resetPasswordEmail")
        if (resetPasswordEmail) {
            setHideResetPasswordAlert(false)
            setUsername(resetPasswordEmail)
            localStorage.removeItem("resetPasswordEmail")
            window.scrollTo({ top: 0 })
        }
    }, [])



    function handleError(error: AxiosError<BaseResponse<void>>) {

        const BAD_CREDENTIALS = "500"
        const INVALID_CAPTCHA = "600"
        const ERRORS = error.response?.data?.errors || {}
        const FORM_ERRORS: string[] = []

        addErrorIf(INVALID_CAPTCHA in ERRORS, FORM_ERRORS, INVALID_CAPTCHA, setInvalidCaptcha)
        addErrorIf(BAD_CREDENTIALS in ERRORS, FORM_ERRORS, BAD_CREDENTIALS)
        setErrorMessages(FORM_ERRORS)
        setTitle("Erreur - " + CONSTANTS.DEFAULT_LOGIN_PAGE_TITLE)
        captchetatRef.current?.reloadImage()
        setCaptchaCode("")
        window.scrollTo({ top: 0 })
    }

    const submitLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setInvalidCaptcha("")
        setErrorMessages([])

        const requestBody = {
            username: username,
            password: password,
            captcha: {
                code: captchaCode,
                uuid: captchaUuid
            }
        }

        login(requestBody)
            .then(redirectToApp)
            .catch(handleError)
    }

    return (
        <>
            <Helmet><title>{title}</title></Helmet>
            <ErrorAlert errors={errorMessages} />
            <Alert hidden={hideResetPasswordAlert} type="success">
                <p>
                    <strong>Un email vous a été envoyé</strong> à l’adresse indiquée. Pour créer votre nouveau mot de passe, <strong>cliquez sur le lien reçu dans cet email.</strong>
                </p>
            </Alert>
            <Description title="Me connecter" />
            <FranceConnect />
            <BlueRoundedOr />
            <h2 className="fr-h5">Utiliser vos identifiants</h2>
            <form onSubmit={submitLogin}>
                <Alert className="fr-mb-6w">
                    <p>Les <strong>informations </strong>demandées sont <strong>obligatoires </strong></p>
                </Alert>
                <Input id="username" type="email" name="username" required
                    label="Adresse électronique (exemple: nom@exemple.fr)"
                    hint="Par exemple : nom@example.com" value={username} onChange={setUsername} />
                <Input id="password" type="password" name="password" required label="Votre mot de passe"
                    value={password} onChange={setPassword} />
                <AuthLink to="/reset-password-request" message="Mot de passe oublié ?" />
                <AuthLink to="/create-account" message="C'est votre première connexion ?" />
                <Callout className="fr-mb-6w" icon="fr-icon-feedback-line" color="clear">
                    <p>Ce service en ligne <strong>n'est pas lié</strong> à votre compte service-public.fr</p>
                </Callout>
                <DileCaptcha captchaCode={captchaCode} setCaptchaCode={setCaptchaCode} setCaptchaUuid={setCaptchaUuid}
                    captchetatRef={captchetatRef} error={invalidCaptcha} />
                <SubmitButton title="Se connecter" />
            </form >
        </>
    )
}

export default Login
