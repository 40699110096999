declare const window: any

const BACK_URL = window.BACK_URL || "http://localhost:8080"

const Config = {
	BACK_URL: BACK_URL,
	APP_URL: window.APP_URL || "http://localhost:3100",
	DILE_CAPTCHA_ENABLED: window.DILE_CAPTCHA_ENABLED ? stringToBoolean(window.DILE_CAPTCHA_ENABLED) : true,
	URL_ME: `${BACK_URL}/authn/me`,
	URL_LOGIN: `${BACK_URL}/login`,
	URL_LOGOUT: `${BACK_URL}/logout`,
	URL_CREATE_ACCOUNT: `${BACK_URL}/authn/account`,
	URL_RESET_PASSWORD_REQUEST: `${BACK_URL}/authn/request-reset-password`,
	URL_RESET_PASSWORD_CONFIRMATION: `${BACK_URL}/authn/confirm-reset-password`,
	URL_CHECK_RESET_PASSWORD_CODE: `${BACK_URL}/authn/check-reset-password-code`,
	URL_SIMPLE_CAPTCHA_ENDPOINT: `${BACK_URL}/simple-captcha-endpoint`,
	URL_FRANCE_CONNECT: `${BACK_URL}/oauth2/authorization/fc`,
	URL_RESEND_CREATE_ACCOUNT_VALIDATION_EMAIL: `${BACK_URL}/authn/account/resend-validation-email`
}

function stringToBoolean(value: string): boolean {
	return value.toLowerCase() !== "false"
}

export default Config
