import React, { useRef, useState } from "react"
import Alert from "../../components/Alert/Alert"
import Input from "../../components/Input/Input"
import AuthLink from "../../components/AuthLink/AuthLink"
import DileCaptcha from "../../components/DileCaptcha/DileCaptcha"
import { AxiosError } from "axios"
import { CONSTANTS } from "../../utils/constants"
import { BaseResponse, requestResetPassword } from "../../services/api"
import { useNavigate } from "react-router-dom"
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert"
import { Helmet } from "react-helmet"
import SubmitButton from "../../components/SubmitButton/SubmitButton"
import { addErrorIf } from "../../utils/utils"
import Description from "../../components/Description/Description"

const ResetPasswordRequest: React.FC = () => {
    const [email, setEmail] = useState<string>("")
    const [captchaUuid, setCaptchaUuid] = useState<any>()
    const [captchaCode, setCaptchaCode] = useState<any>()
    const captchetatRef = useRef<any>(null)
    const [invalidCaptcha, setInvalidCaptcha] = useState<string>()
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [title, setTitle] = useState<string>(CONSTANTS.DEFAULT_RESET_PASSWORD_REQUEST_PAGE_TITLE)
    const navigate = useNavigate()

    function handleError(error: AxiosError<BaseResponse<void>>) {
        const INVALID_CAPTCHA = "600"
        const ERRORS = error.response?.data?.errors || {}
        const FORM_ERRORS: string[] = []

        addErrorIf(INVALID_CAPTCHA in ERRORS, FORM_ERRORS, INVALID_CAPTCHA, setInvalidCaptcha)
        setErrorMessages(FORM_ERRORS)
        setTitle("Erreur - " + CONSTANTS.DEFAULT_RESET_PASSWORD_REQUEST_PAGE_TITLE)
        captchetatRef.current?.reloadImage()
        setCaptchaCode("")
        window.scrollTo({ top: 0 })
    }

    const handleSuccess = () => {
        localStorage.setItem('resetPasswordEmail', email)
        navigate("/login")
    }

    const submitResetPasswordRequest = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setInvalidCaptcha("")
        setErrorMessages([])

        const requestBody = {
            email: email,
            captcha: {
                code: captchaCode,
                uuid: captchaUuid
            }
        }

        requestResetPassword(requestBody)
            .then(handleSuccess)
            .catch(handleError)

    }
    return (
        <>
            <Helmet><title>{title}</title></Helmet>
            <ErrorAlert errors={errorMessages} />
            <Description title="Mot de passe oublié ?" resetPassword />
            <h2 className="fr-h5">Obtenir un nouveau mot de passe</h2>
            <form onSubmit={submitResetPasswordRequest}>
                <Alert className="fr-mb-6w">
                    <p>Les <strong>informations </strong>demandées sont <strong>obligatoires</strong></p>
                </Alert>
                <Input id="email" type="email" name="email" required
                    label="Quelle est votre adresse email ?"
                    labelClass="fr-h6 fr-mt-6w"
                    hint="Par exemple : nom@example.com" value={email} onChange={setEmail} />
                <AuthLink to="/login" message="Mot de passe retrouvé ?" />
                <AuthLink to="/create-account" message="C'est votre première connexion ?" />
                <DileCaptcha captchaCode={captchaCode} setCaptchaCode={setCaptchaCode} setCaptchaUuid={setCaptchaUuid}
                    captchetatRef={captchetatRef} error={invalidCaptcha} />
                <SubmitButton title="Continuer" />
            </form>
        </>
    )
}

export default ResetPasswordRequest