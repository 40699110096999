import React from "react"
import CreateAccountConfirmation from "./CreateAccountConfirmation"
import CreateAccountForm from "./CreateAccountForm"
import { useLocation } from "react-router-dom"

const CreateAccount: React.FC = () => {
    const location = useLocation()
    const resendEmailCode = location.state?.resendEmailCode
    const email = location.state?.email
    return (
        <>
            {
                resendEmailCode ?
                    <CreateAccountConfirmation email={email} code={resendEmailCode} />
                    :
                    <CreateAccountForm />
            }
        </>
    )
}

export default CreateAccount