import React from "react"

interface CGUCheckboxProps {
    name: string,
    id: string,
    value?: boolean,
    onChange?: (value: boolean) => void
}

const CGUCheckbox: React.FC<CGUCheckboxProps> = ({ onChange = () => { }, ...props }) => {
    return (
        <>
            <div className="fr-mb-1w">
                <fieldset className="fr-fieldset" id="checkboxes" aria-labelledby="checkboxes-legend checkboxes-messages">
                    <legend className="fr-fieldset__legend--regular fr-fieldset__legend fr-sr-only" id="checkboxes-legend">
                        Conditions générales d'utilisation
                    </legend>
                    <div className="fr-fieldset__element">
                        <div className="fr-checkbox-group fr-checkbox-group--sm">
                            <input name={props.name} id={props.id} type="checkbox"
                                checked={props.value} onChange={e => onChange(e.target.checked)}
                                required aria-required="true"
                            />
                            <label className="fr-label" htmlFor={props.id}>
                                J’accepte que les informations saisies dans le formulaire soient utilisées pour créer mes identifiants et déclare accepter les conditions générales d’utilisation de ce service
                            </label>
                            <div className="fr-messages-group" id="checkboxes-1-messages" aria-live="assertive">
                            </div>
                        </div>
                    </div>
                </fieldset >
            </div>
            <p className="fr-hint-text fr-mb-0" id="cgu-hint-1">
                Pour connaitre et exercer vos droits relatifs à l’utilisation des données collectées par ce formulaire, veuillez consulter
                nos                <a href="https://www.service-public.fr/P10128"
                    title='conditions générales d’utilisation de service-public.fr - Nouvelle fenêtre' target="_blank"
                    rel="noopener noreferrer external">conditions générales d’utilisation</a>
            </p>
        </>
    )
}

export default CGUCheckbox