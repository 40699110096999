import React from "react"

interface InputProps {
	id: string,
	type: string,
	name: string,
	required: boolean,
	label: string,
	hint?: string,
	value?: string,
	error?: string,
	labelClass?: string,
	onChange?: (value: string) => void
}

const Input: React.FC<InputProps> = ({ onChange = () => { }, ...otherProps }) => {
	const handleInvalid = (event: any) => {
		if (otherProps.type === 'email') {
			event.target.setCustomValidity('Veuillez saisir une adresse valide (exemple : jean.dupont@exemple.fr)')
		}
	}

	const handleInput = (event: any) => {
		if (otherProps.type === 'email') {
			event.target.setCustomValidity('')
		}
	}

	return (
		<div className={otherProps.error ? "fr-input-group fr-input-group--error" : "fr-input-group"}>
			<label htmlFor={otherProps.id}
				className={otherProps.labelClass ? `${otherProps.labelClass} fr-label` : "fr-label"}>
				{otherProps.label}
			</label>
			{otherProps.hint && <span className="fr-hint-text" id={`${otherProps.id}-hint`}>
				{otherProps.hint}
			</span>}

			<input id={otherProps.id} type={otherProps.type} name={otherProps.name}
				className={otherProps.error ? "fr-input fr-input--error" : "fr-input"}
				{...(otherProps.required && { required: true, 'aria-required': true })}
				aria-describedby={otherProps.hint ? `${otherProps.id}-hint ${otherProps.id}-error` : `${otherProps.id}-error`}
				{...(otherProps.error && { "aria-invalid": true })}
				autoComplete={otherProps.type === "email" ? "email" : "off"}
				value={otherProps.value} onChange={e => onChange(e.target.value)}
				onInvalid={handleInvalid} onInput={handleInput}/>
			{
				otherProps.error && <p id={`${otherProps.id}-error`} className="fr-error-text">
					{otherProps.error}
				</p>
			}
		</div>
	)
}

export default Input