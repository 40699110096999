import React from "react"

function BlueRoundedOr() {
    return (
        <p className="dile-text--center dile-login-ou fr-text--lg fr-my-10w">
            <span>ou</span>
        </p>
    )

}

export default BlueRoundedOr