import React from "react"
import Config from "../../../config"
import { Link } from "react-router-dom"

function FranceConnect() {

	return (
		<div className="dile-text--center">
			<div className="fr-connect-group">
				<Link to={Config.URL_FRANCE_CONNECT}>
					<button className="fr-connect">
						<span className="fr-connect__login">S’identifier avec</span>
						<span className="fr-connect__brand">FranceConnect</span>
					</button>
				</Link>
				<p>
					<a href="https://franceconnect.gouv.fr/" target="_blank" rel="noopener noreferrer" title="Qu’est-ce que FranceConnect ? - nouvelle fenêtre">Qu’est-ce que FranceConnect ?</a>
				</p>
			</div>
			<p className="fr-text--sm">
				En utilisant le service FranceConnect,<br />
				vous acceptez les conditions générales d'utilisation de<br />
				<a href="https://franceconnect.gouv.fr/cgu" title="Les conditions générales d'utilisation du service FranceConnect - franceconnect.gouv.fr - Nouvelle fenêtre" target="_blank" rel="noopener noreferrer">FranceConnect</a>
				<span> et de </span><a href="https://www.service-public.fr/P10128" title="Les conditions générales d'utilisation de la démarche | Nouvelle fenêtre" target="_blank" rel="noopener noreferrer">cette démarche</a>
			</p>
		</div>
	)

}

export default FranceConnect