import React from "react"

interface SubmitButtonProps {
	title: string
}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
	return (
		<ul className="fr-btns-group">
			<li>
				<button type="submit" className="fr-btn fr-mb-4v fr-col-12" title={props.title}>
					{props.title}
				</button>
			</li>
		</ul>
	)
}

export default SubmitButton