import React from "react"
import Callout from "../Callout/Callout"

interface PasswordPolicyProps {
    title?: string
}

const PasswordPolicy: React.FC<PasswordPolicyProps> = (props) => {

    return (
        <>
            {
                props.title &&
                <h3 className="fr-h6">
                    {props.title}
                </h3>
            }
            <Callout icon="fr-icon-feedback-line" color="clear">
                <h4 className="fr-callout__title">
                    Pour créer votre mot de passe utilisez :
                </h4>
                <ul className="fr-pl-2w">
                    <li>au moins <strong>8 caractères</strong></li>
                    <li>au moins <strong>1 lettre en majuscule</strong></li>
                    <li>au moins <strong>1 lettre en minuscule</strong></li>
                    <li>au moins <strong>1 chiffre</strong></li>
                </ul>
                <p className="fr-text--sm">
                    <a title='Lire les recommandations de la Commission Nationale de l’Informatique et des Libertés (CNIL) - cnil.fr - Nouvelle fenêtre'
                        href="https://www.cnil.fr/fr/authentification-par-mot-de-passe-les-mesures-de-securite-elementaires" target="_blank"
                        rel="noopener noreferrer external">
                        Lire les recommandations de la Commission Nationale de l’Informatique et des Libertés (CNIL)
                        <span aria-hidden="true" className="icon icon-external-link"></span>
                    </a>
                </p>
            </Callout>
        </>
    )

}

export default PasswordPolicy