import React from "react"
import "./Description.css"

interface DescriptionProps {
	title: string
	resetPassword?: boolean,
	hiddenMessage?: boolean
}

const Description: React.FC<DescriptionProps> = (props) => {
	return (
		<>
			<h1 className="dile-auth-context-titre fr-mb-2w dile-text--center">
				<span className="dile-auth-context-titre-icon-container dile-text--center">
					<span className="ri-arrow-left-right-line" aria-hidden="true"></span>
				</span>
				<span className="dile-auth-context-titre-label fr-pt-0">{props.title}</span>
			</h1>
			<div {...(props.hiddenMessage && { className: "dile-display--none" })}>
				{
					props.resetPassword ?
						<p className="fr-mb-8w dile-text--center">
							Nous pouvons<strong> vous envoyer un email</strong> <br />
							qui permettra de<strong> créer un nouveau mot de passe</strong> <br />
							pour ce service en ligne</p>
						:
						<p className="fr-mb-8w dile-text--center">
							<strong>Pour faire votre demande</strong> <br />
							identifiez-vous <strong>soit avec FranceConnect</strong>, <br />
							<strong>soit en créant vos identifiants</strong> pour cette démarche.
						</p>
				}
			</div>
		</>
	)
}

export default Description