import React from "react"
import { Helmet } from "react-helmet"
import Alert from "../../components/Alert/Alert"
import { CONSTANTS } from "../../utils/constants"
import Description from "../../components/Description/Description"

interface ResetPasswordConfirmationCodeNotFoundProps {
    message: string
}

const ResetPasswordConfirmationCodeNotFound: React.FC<ResetPasswordConfirmationCodeNotFoundProps> = ({ message }) => {

    return (
        <>
            <Helmet><title>Le lien a expiré - {CONSTANTS.DEFAULT_LOGIN_PAGE_TITLE}</title></Helmet>
            <Description hiddenMessage title="Le lien a expiré" />
            <Alert className="fr-mb-6w">
                <ul>
                    <li>{message}</li>
                </ul>
                <p><a id="newTokenLink" href="/reset-password-request">Recevoir un nouveau lien par email</a></p>
            </Alert>

        </>
    )
}

export default ResetPasswordConfirmationCodeNotFound
